import React from 'react';
import i18n from 'i18n-smart';
import scrollToElement from '../hooks/scrollToElement';
import img from '../images/img-2.svg';
import Button from './Button';

const Intro = () => {
  return (
    <div className='m-auto max-w-6xl p-2 md:p-12 h-5/6' id='about'>
      <div
        className='flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left'
        data-aos='fade-up'
      >
        <div className='lg:w-1/2 flex flex-col lg:mx-4 justify-center'>
          <img alt='card img' className='rounded-t float-right' src={img} />
        </div>
        <div
          className='flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8'
          data-aos='zoom-in'
          data-aos-delay='500'
        >
          <h3 className='mb-5 text-3xl font-medium text-accent-dark font-montserrat'>
            {i18n.value('about.title')}
          </h3>
          <div>
            <p className='my-3 text-xl text-dark font-base'>{i18n.value('about.text')}</p>
          </div>

          <div>
            <p className='my-3 text-xl text-dark font-base'>{i18n.value('about.text2')}</p>
          </div>
          <Button onClick={() => scrollToElement('#footer')}> {i18n.value('about.button')}</Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
