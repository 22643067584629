import { createContext, useContext } from 'react';

const LocaleContext = createContext({
  locale: '',
  toggleLocale() {},
  toggleValue: '',
});

export const useLocaleContext = () => useContext(LocaleContext);
export default LocaleContext;
