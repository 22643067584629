import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
  return (
    <>
      <HashLink className='px-4 font-extrabold text-gray-500 hover:text-accent-dark' smooth to='/#about'>
        About
      </HashLink>
      <HashLink
        className='px-4 font-extrabold text-gray-500 hover:text-accent-dark'
        smooth
        to='/#services'
      >
        Services
      </HashLink>
      <HashLink className='px-4 font-extrabold text-gray-500 hover:text-accent-dark' to='/'>
        Portfolio
      </HashLink>
      <HashLink className='px-4 font-extrabold text-gray-500 hover:text-accent-dark' to='/contact'>
        Contact Us
      </HashLink>
      <Link
        className='text-white bg-accent-dark hover:bg-accent-dark-hover inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl'
        smooth
        to='/get-demo'
      >
        Demo our products
      </Link>
    </>
  );
};

export default NavLinks;
