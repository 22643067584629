import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18n-smart';

import { contacts } from '../constants';
import Logo from '../images/logo_header.svg';

const Footer = () => {
  return (
    <>
      <footer id='footer'>
        <div className='footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30'>
          {/* Top area: Blocks */}
          <div className='flex flex-col justify-start items-start lg:flex-row gap-5 py-8 md:py-12 border-t border-gray-200 text-left  lg:items-center lg:justify-between'>
            {/* 1st block */}
            <div className='py-5 hidden lg:block'>
              <img src={Logo} className='inline-block' width={180} alt='' />
            </div>
            {/* 2nd block */}
            <div className=' '>
              <h6 className='text-[#013289]text-xl font-bold mb-4 uppercase'>
                {i18n.value('footer.title.address')}
              </h6>
              <ul className='text-md'>
                <li className='mb-2'>
                  <p className='text-[#013289]transition duration-250 ease-in-out'>
                    {i18n.value('footer.address')}
                  </p>
                </li>
              </ul>
            </div>
            {/* 3rd block */}
            <div className=' '>
              <h6 className='text-[#013289]text-xl font-bold mb-4 uppercase'>
                {i18n.value('footer.title.phone')}
              </h6>
              <ul className='text-md'>
                <li className='mb-2'>
                  <Link
                    to={contacts.phone.link}
                    className='text-[#013289]transition duration-250 ease-in-out'
                  >
                    {contacts.phone.text}
                  </Link>
                </li>
              </ul>
            </div>
            {/* 4rd block */}
            <div className=''>
              <h6 className='text-[#013289]text-xl font-bold mb-4 uppercase'>
                {' '}
                {i18n.value('footer.title.email')}
              </h6>
              <ul className='text-md'>
                <li className='mb-2'>
                  <Link
                    to={contacts.email.link}
                    className='text-[#013289]transition duration-250 ease-in-out'
                  >
                    {contacts.email.text}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-wrap items-center md:justify-between justify-center mx-auto px-4'>
            <div className='w-full md:w-4/12 px-4 mx-auto text-center py-2'>
              <div className='text-sm text-light font-thin py-1 lg:text-dark'>
                {i18n.value('copyright', [new Date().getFullYear()])}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
