import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// All pages
import Home from './pages/Home';
import { companyName } from './constants';
// import Contact from './pages/Contact';
// import DemoProduct from './pages/DemoProduct';
import NotFound from './pages/404';
import { LocaleProvider } from './hooks/localisation';
import ScrollToTop from './hooks/ScrollToTop';

import { useDocTitle } from './components/CustomHook';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle(companyName);
  return (
    <Router>
      <ScrollToTop>
        <LocaleProvider renderOnUpdate>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </LocaleProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
