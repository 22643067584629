// import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';

const Home = () => {
  return (
    <div>
      <Hero />
      <Intro />
      <Services usePromo={false} />
      <Portfolio />
      {/*<Clients />*/}
      <Cta messageLink={false} />
      <Footer />
    </div>
  );
};

export default Home;
