import { useState } from 'react';
import i18n, { configure } from 'i18n-smart';
import reactInterpolatePlugin from 'i18n-smart/lib/plugins/react';

import LocaleContext, { useLocaleContext } from './LocaleContext';
import translationsEn from './translations.en';
import translationsRu from './translations.ru';

const locales = {
  ru: 'RU',
  en: 'EN',
};

configure({
  locale: locales.en,
  defaultValues: translationsEn,
  plugins: [reactInterpolatePlugin],
});

function LocaleProvider({ children, renderOnUpdate = false }) {
  const [locale, setLocale] = useState(locales.en);

  const toggleLocale = () => {
    const nextLocale = locale === locales.en ? locales.ru : locales.en;
    const nextValues = locale === locales.en ? translationsRu : translationsEn;

    setLocale(nextLocale);
    i18n.setValues(nextValues, nextLocale);
  };

  return (
    <LocaleContext.Provider
      value={{
        locale,
        toggleLocale,
      }}
    >
      <div className='locale-provider' key={renderOnUpdate ? locale : ''}>
        {children}
      </div>
    </LocaleContext.Provider>
  );
}

export { useLocaleContext, LocaleProvider, locales };
