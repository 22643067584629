import React from 'react';
import i18n from 'i18n-smart';

import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/main-img.svg';
import scrollToElement from '../hooks/scrollToElement';
import Button from './Button';

const Hero = () => {
  return (
    <>
      <div className='hero w-full' id='hero'>
        <div>
          <NavBar useMenu={false} />
        </div>

        <div className='m-auto overflow-hidden mt-8 lg:mt-4 p-2 md:p-12 h-5/6' data-aos='zoom-in'>
          <div className='flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left'>
            <div
              className='lg:w-1/2 flex flex-col justify-center'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <h1 className='mb-5 md:text-5xl text-3xl font-medium text-accent-dark font-montserrat'>
                {i18n.value('hero.title')}
              </h1>
              <div className='text-xl font-base tracking-tight mb-5 text-dark'>
                {i18n.value('hero.slogan')}
              </div>
              <div className='mb-4 space-x-0 md:space-x-2 md:mb-8'>
                <Button onClick={() => scrollToElement('#about')}>
                  {i18n.value('hero.button')}
                </Button>
                {/*<Link*/}
                {/*  to='/contact'*/}
                {/*  className='text-white bg-accent-dark hover:bg-accent-dark-hover inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0'*/}
                {/*>*/}
                {/*  Get Started*/}
                {/*  <svg*/}
                {/*    className='w-4 h-4 ml-1'*/}
                {/*    xmlns='http://www.w3.org/2000/svg'*/}
                {/*    viewBox='0 0 20 20'*/}
                {/*    fill='currentColor'*/}
                {/*  >*/}
                {/*    <path*/}
                {/*      fillRule='evenodd'*/}
                {/*      d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'*/}
                {/*      clipRule='evenodd'*/}
                {/*    ></path>*/}
                {/*  </svg>*/}
                {/*</Link>*/}
                {/*<a*/}
                {/*  href='#_'*/}
                {/*  className='inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0'*/}
                {/*>*/}
                {/*  Learn More*/}
                {/*  <svg*/}
                {/*    className='w-4 h-4 ml-1'*/}
                {/*    fill='none'*/}
                {/*    stroke='currentColor'*/}
                {/*    viewBox='0 0 24 24'*/}
                {/*    xmlns='http://www.w3.org/2000/svg'*/}
                {/*  >*/}
                {/*    <path*/}
                {/*      strokeLinecap='round'*/}
                {/*      strokeLinejoin='round'*/}
                {/*      strokeWidth='2'*/}
                {/*      d='M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z'*/}
                {/*    ></path>*/}
                {/*  </svg>*/}
                {/*</a>*/}
              </div>
            </div>
            <div
              className='flex lg:justify-end w-full lg:w-1/2'
              data-aos='fade-up'
              data-aos-delay='700'
            >
              <img
                alt='card img'
                className='rounded-t float-right duration-1000 w-full'
                src={heroImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
